<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid card_modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t('viewCredential.modal.title') }}

            <v-spacer />
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body px-16 pb-16 pt-2 mb-1">
          <slot name="body">
            <v-col
              v-if="imgArray.length == 0"
              cols="12"
              md="12"
              class="pb-16 pt-7 px-16"
            >
              <CustomCard
                class="mx-a"
                :large="true"
                :frontTemplate="templateValues"
                :backTemplate="backTemplate"
                :tableValues="tableValues"
                :caName="caName"
                :credentialName="userData.certName"
                :urlPhoto="photoURL"
                :frontend_props="frontend_props"
                :width="458"
                :height="323"
              />
            </v-col>
            <Spinner v-show="!isLoaded" />

            <v-col
              v-if="imgArray.length > 0"
              cols="12"
              style="max-height: 320px; overflow-y: auto"
            >
              <v-img
                v-for="img in imgArray"
                :key="img"
                :src="img"
                @load="onImgLoad"
                contain
                max-width="420px"
                class="mx-auto"
              />
            </v-col>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="delete" @click="cancel()">
                {{ $t('button.cancel') }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import IconClose from '../assets/icon-close.vue';
import CustomCard from '../components/CustomCard';
import Spinner from '@/components/Spinner';

export default {
  name: 'IssueCertificate',
  components: {
    CustomCard,
    IconClose,
    Spinner,
  },

  created: function () {
    console.log(this.userData);
    let frontTemplateObj = JSON.parse(JSON.stringify(this.userData.user_data));
    delete frontTemplateObj.tables;
    console.log(this.templateItens);

    console.log('frontend_props', this.frontend_props);
    if (
      this.userData?.frontend_props?.customTemplateName == 'templateEditor' ||
      this.userData?.imgArray?.length > 0
    ) {
      // this.$store.dispatch('setTemplate', this.frontend_props);
      // this.$store.commit('setActiveComponent', '');
      this.imgArray = this.userData.imgArray;
      // } else {
      //   this.$store.commit(
      //     'template/frontend_props',
      //     this.userData.frontend_props || this.frontend_props
      //   );
    } else {
      this.isLoaded = true;
    }

    if (this.templateItens) {
      for (let index = 0; index < this.templateItens.length; index++) {
        const el = this.templateItens[index];
        if (this.userData.user_data[el.attr] && el.order > -1) {
          this.templateValues[el.order] = {
            attr: el.attr,
            value: this.userData.user_data[el.attr],
          };
        } else if (this.userData.user_data[el.attr]) {
          this.templateValues.push({
            attr: el.attr,
            value: this.userData.user_data[el.attr],
          });
        }
      }
    } else {
      for (var a in frontTemplateObj) {
        var val = frontTemplateObj[a];
        this.templateValues.push({ attr: a, value: val });
      }
    }

    if (this.userData.user_data.tables) {
      this.backTemplate = JSON.parse(
        JSON.stringify(this.userData.user_data.tables)
      );
      this.tableValues = this.backTemplate.values;
      console.log(this.backTemplate);
    }
  },
  props: {
    userData: {
      required: true,
    },
    templateItens: {
      required: true,
    },
    frontend_props: {
      default: () => {},
    },
  },
  watch: {},
  computed: {
    ...mapGetters(['caName', 'photoURL']),
    ...mapGetters('template', [
      'excelTemplate',
      'tid',
      'caName',
      'credName',
      'urlPhoto',
      'cardHeaders',
      'customTemplateName',
    ]),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    cancel() {
      this.$emit('cancel');
    },
    onImgLoad() {
      this.isLoaded = true;
    },
  },
  data() {
    return {
      isLoaded: false,
      tableValues: [],
      backTemplate: null,
      templateValues: [],
      imgArray: [],
    };
  },
};
</script>

<style lang="scss">
.modal-wallid.card_modal {
  position: relative;
  .passepartout {
    background: white;
    border-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .page {
      box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
      textarea {
        overflow: hidden;
      }
    }
  }
  .modal-body {
    max-height: 460px;
  }
  .v-stepper {
    box-shadow: none;
  }
  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
  }
}
</style>
