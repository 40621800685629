<template>
  <div>
    <v-data-table
      class="dca-table"
      :search="search"
      :headers="headers[$i18n.locale]"
      :items="items"
      :no-results-text="$t('table.noData')"
      :items-per-page="10"
      :hide-default-footer="items.length < 10"
    >
      <template v-slot:[`header.actions`]="{ item }">
        <v-menu bottom left :nudge-width="200" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="tableOptions"
              :ripple="false"
              class="dot-menu"
              icon
              v-on="on"
            >
              <IconMenu />
            </v-btn>
          </template>

          <slot name="actions">
            <v-list :ripple="false" class="dot-menu">
              <v-list-item :ripple="false" @click="approveAll(item)">
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.all')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </slot>
        </v-menu>
      </template>
      <template v-slot:[`item.public_field`]="{ item }">
        {{ item.public_field.value }} ({{ item.public_field.attr }})
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div>
          <IconPending v-if="item.status == PENDING_APPROVAL" />
          <IconWaiting v-if="item.status == WAITING_WALLET" />
          <IconApproved v-if="item.status == APPROVED" />
          <IconRevoked v-if="item.status == REVOKED" />

          {{ $t('status.' + item.status) }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          v-if="item.status != REVOKED"
          bottom
          left
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false" class="dot-menu" icon v-on="on">
              <IconMenu />
            </v-btn>
          </template>

          <slot name="actions">
            <v-list :ripple="false" class="dot-menu">
              <v-list-item :ripple="false" @click.stop @click="view(item)">
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.view')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                v-if="
                  item.status == PENDING_APPROVAL || item.status == APPROVED
                "
                v-on:click="download(item)"
              >
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.download')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                v-if="item.status == PENDING_APPROVAL"
                v-on:click="approve(item)"
              >
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.approve')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                v-if="item.status == APPROVED"
                v-on:click="revoke(item)"
              >
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.revoke')
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                v-if="
                  item.status == PENDING_APPROVAL ||
                  item.status == WAITING_WALLET
                "
                v-on:click="cancel(item)"
              >
                <v-list-item-title class="menu-title">{{
                  $t('table.menu.cancel')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </slot>
        </v-menu>
      </template>
    </v-data-table>

    <ViewCredCardModal
      v-if="showViewCredentialCardModal"
      @close="
        (showViewCredentialCardModal = false),
          ((userData = {}), $emit('updateTable'))
      "
      @cancel="
        (showViewCancelCertModal = true), (showViewCredentialCardModal = false)
      "
      :frontend_props="frontend_props"
      :userData="userData"
      :templateItens="templateItens"
    />

    <RevokeCertModal
      v-if="showRevokeCertModal"
      @close="
        (showRevokeCertModal = false), (userData = {}), $emit('updateTable')
      "
      :userData="userData"
      @back="back"
      @cancel="cancel"
    />
    <ViewCancelCertModal
      v-if="showViewCancelCertModal"
      @close="
        (showViewCancelCertModal = false), (userData = {}), $emit('updateTable')
      "
      :userData="userData"
      @back="back"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import IconPending from '../../assets/icon-pending';
import IconWaiting from '../../assets/icon-waiting-wallet.vue';
import IconRevoked from '../../assets/icon-revoked';
import IconApproved from '../../assets/icon-approved';
import ViewCredCardModal from '../../modals/ViewCredentialCardModal';
import ViewCancelCertModal from '../../modals/ViewCancelCertModal';
import RevokeCertModal from '../../modals/RevokeCertModal';

import IconMenu from '../../assets/icon-dot-menu';
import { APPROVE } from '../../store/actions';
export default {
  name: 'DataTable',
  created() {},
  components: {
    IconPending,
    IconApproved,
    IconRevoked,
    IconWaiting,
    ViewCredCardModal,
    ViewCancelCertModal,
    IconMenu,
    RevokeCertModal,
  },
  data() {
    return {
      userData: {},
      showViewCredentialCardModal: false,
      showViewCancelCertModal: false,
      showRevokeCertModal: false,
      snackbar: false,
      frontend_props: null,
      templateItens: null,
    };
  },
  methods: {
    getTemplate(tid) {
      let template = this.$store.state.templates;

      return template.find((t) => t.tid == tid);
    },
    view(data) {
      // this.$emit("view", key);
      console.log(data);
      console.log(this.userData);
      let template = this.getTemplate(data.tid);
      this.userData = data;
      this.userData.certName = template.name;
      this.frontend_props = template.frontend_props;
      this.templateItens = template.templateItens;
      console.log(this.userData.certName);
      // for (var a in data.user_data) {
      //   var val = data.user_data[a];
      //   arr.push({ attr: a, value: val });
      // }

      // this.userData.id = data.id;
      // this.userData.tid = data.tid;
      this.showViewCredentialCardModal = true;
    },
    download(item) {
      console.log('download credential', item);
      let PDF_URL;

      if (item.pdf_url) {
        window.open(item.pdf_url);
      } else {
        if (process.env.VUE_APP_AMBIENT == 'DEMO') {
          PDF_URL = 'https://demo.mycredentials.wallid.io/ViewCredential/';
        } else {
          PDF_URL = 'https://mycredentials.wallid.io/ViewCredential/';
        }
        window.open(PDF_URL + item.id);
      }
    },
    approve(user) {
      this.$store
        .dispatch(APPROVE, { user })
        .then(() => {
          this.$emit('updateTable');
        })
        .catch((err) => {
          let errorMsg = err.message || err;
          console.log(errorMsg);
          this.$store.commit('errorMsg', errorMsg);
        });
    },
    revoke(data) {
      this.userData.id = data.id;
      this.userData.tid = data.tid;
      this.showRevokeCertModal = true;
    },
    cancel(data) {
      this.userData.id = data.id;
      this.userData.tid = data.tid;
      this.showViewCancelCertModal = true;
    },
    back() {
      this.showRevokeCertModal = false;
      this.showViewCancelCertModal = false;
      if (this.userData.user_data) {
        this.showViewCredentialCardModal = true;
      }
    },
    approveAll(item) {
      console.log(item);

      for (let index = 0; index < this.items.length; index++) {
        this.approve(this.items[index]);
      }
    },
  },
  props: {
    search: {},
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    tableOptions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.v-data-table.dca-table .v-data-table__wrapper {
  box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;

  & table > tbody > tr:hover {
    background-color: var(--white) !important;
  }
  & table thead > tr > th {
    padding: 0 16px;
  }
  & table tbody > tr > td,
  & table thead > tr > th,
  & table tfoot > tr > td {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }

  & tr > td {
    svg {
      margin-bottom: -5px;
      margin-right: 4px;
    }
  }
  & table thead > tr > th:last-child,
  & table tbody > tr:not(.v-data-table__empty-wrapper) > td:last-child {
    text-align: end !important;
    padding-left: 0;
  }
  & table thead > tr > th:last-child {
    padding-right: 4px;
  }
  & table tbody > tr > td:last-child {
    padding: 0 4px;
  }
  & table thead tr > th {
    font-weight: 600;
  }

  th {
    padding: 16px;
  }
}
</style>
