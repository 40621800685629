<template>
  <v-layout align-center column justify-center>
    <v-flex xs2 class="spinner">
      <div class="half-circle-spinner">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .layout {
    display: block;
  }
}
</style>
<style>
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
  margin: auto;
}

.half-circle-spinner {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(30px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #01a3b0;
  animation: half-circle-spinner-animation 2s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #035b60;
  animation: half-circle-spinner-animation 2s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
