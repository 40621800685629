<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-wallid card_modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{ $t('revokeModal.title') }}

            <v-spacer />
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body text-center px-16 pb-9">
          <slot name="body">
            <v-col cols="12" md="12" class="pb-16 pt-7 px-16 text-center">
              <p v-html="$t('revokeModal.text')"></p>
            </v-col>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="back-type-2 mr-4" @click="back()">{{
                $t('button.back')
              }}</v-btn>
              <v-btn
                class="delete"
                :loading="loading"
                :disabled="loading"
                @click="cancel()"
              >
                {{ $t('revokeModal.button') }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import IconClose from '../assets/icon-close.vue';
import { REVOKE } from '../store/actions';

export default {
  name: 'IssueCertificate',
  components: {
    IconClose,
  },
  created() {
    console.log(this.userData);
  },
  props: {
    userData: {
      required: true,
    },
  },
  watch: {},
  computed: {
    ...mapGetters(['caName', 'photoURL', 'loading']),
  },
  methods: {
    cancel() {
      this.$store
        .dispatch(REVOKE, { user: this.userData })
        .then(() => this.close())
        .catch((err) => {
          console.error(err);
          this.$store.commit('errorMsg', err.message || err);
        });
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
